import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../@jumbo/components/Common/formElements/AppSelectBox';
import { requiredMessage } from '../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { getSelectData as countrySelectData } from '../../../routes/app/settings/country/store/action';
import { getSelectDataById as stateSelectDataById } from '../../../routes/app/settings/state/store/action';
import { getSelectDataById as lgaSelectDataById } from '../../../routes/app/settings/lga/store/action';
import { ModeCommentOutlined } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));



const AddEditDialog = ({ open, onCloseDialog, model, onAddSuccess, onUpdateSuccess }) => {
  const classes = useStyles();

//   const { selectedItem, finish } = useSelector(({ locations }) => locations);
  const countryStore = useSelector(state => state.countries);
  const stateStore = useSelector(state => state.states);
  const lgaStore = useSelector(state => state.lgas);

  const [town, setTown] = useState(model?.town);
  const [townError, setTownError] = useState('');
  
  const [lgaId, setLgaId] = useState(model?.lgaid);
  const [lgaError, setLgaError] = useState('');
  const [lgaName, setLgaName] = useState(model?.lga?.name);

  const [stateId, setStateId] = useState(model?.lga?.stateid);
  const [stateError, setStateError] = useState('');
  const [stateName, setStateName] = useState(model?.lga?.state?.name);

  const [countryId, setCountryId] = useState(model?.lga?.state?.countryid);
  const [countryError, setCountryError] = useState('');
  const [countryName, setCountryName] = useState(model?.lga?.state?.country?.name);

  const [currentAddress, setCurrentAddress] = useState(model);

  const dispatch = useDispatch();

  useEffect(() => {

    setCurrentAddress(model);

    dispatch(countrySelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))

    dispatch(stateSelectDataById(countryId, () => {
      // do nothing
    }, () => {
      // do nothing
    }))

    dispatch(lgaSelectDataById(stateId, () => {
      // do nothing
    }, () => {
      // do nothing
    }))
  }, [countryId, stateId]);


  const onSubmitClick = () => {

    if (!town) {
      setTownError(requiredMessage);
    }
    if (!lgaId) {
      setLgaError(requiredMessage);
    }
    if (!stateId) {
      setStateError(requiredMessage);
    }
    if (!countryId) {
      setCountryError(requiredMessage);
    }

    if (town && lgaId && stateId && countryId) {
      onAddressSave();
    }
  };

  const onAddressSave = () => {
    const newAddress = {
      town: town,
      lgaid: lgaId,
      lga: {
          id: lgaId,
          name: lgaName,
          stateid: stateId,
          state: {
              id: stateId,
              name: stateName,
              countryid: countryId,
              country: {
                  id: countryId,
                  name: countryName
              }
          }
      }
    };

    if (currentAddress) {
      if (onUpdateSuccess)
        onUpdateSuccess({ ...currentAddress, ...newAddress });
      onCloseDialog();

    } else {
      if (onAddSuccess)
        onAddSuccess(newAddress);
      onCloseDialog();
    }
  };

  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{currentAddress ? 'Edit Address' : 'New Address'}</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Town"
                  value={town}
                  onChange={e => {
                    setTown(e.target.value);
                    setTownError('');
                  }}
                  helperText={townError}
                />
              </Grid>          
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Country"
                  value={countryId}
                  data={countryStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    var text = e.nativeEvent.target.innerText;
                    setCountryId(e.target.value);
                    setCountryName(text);
                  }}
                  helperText={countryError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="State"
                  value={stateId}
                  data={stateStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    var text = e.nativeEvent.target.innerText;
                    setStateId(e.target.value);
                    setStateName(text);
                  }}
                  helperText={stateError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Lga"
                  value={lgaId}
                  data={lgaStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    var text = e.nativeEvent.target.innerText;
                    setLgaId(e.target.value);
                    setLgaName(text);
                  }}
                  helperText={lgaError}
                />
              </Grid>
            </GridContainer>
          </Box>          
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
                Set Address
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default AddEditDialog;

AddEditDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
