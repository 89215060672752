import React, { useState } from 'react';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography, useTheme } from '@material-ui/core';
import CmtAvatarGroup from '../../../../@coremat/CmtAvatarGroup';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import Box from '@material-ui/core/Box';
import CmtGridView from '../../../../@coremat/CmtGridView';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { getFormattedDate } from '../../../../@jumbo/utils/dateHelper';
import BusinessIcon from '@material-ui/icons/Business';
import CakeIcon from '@material-ui/icons/Cake';
import SchoolIcon from '@material-ui/icons/School';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

const tabs = [
  { id: 33232, title: 'Overview', slug: 'overview' },
  { id: 45345, title: 'Work', slug: 'work' },
  { id: 76545, title: 'Eduction', slug: 'education' },
];

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 3,
      paddingBottom: 0,
    },
  },
  tabsList: {
    position: 'relative',
    minHeight: 60,
    '& .MuiTabs-indicator': {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
  },
  tabItem: {
    maxWidth: 'none',
    minWidth: 10,
    minHeight: 60,
    padding: '5px 10px',
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
  },
  columnRoot: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.5px',
  },
}));

const BioData = ({ userDetail }) => {
  const [tabValue, setTabValue] = useState('overview');
  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { memberid, lastname, firstname, othernames, sstatus, 
    age, dob, sgender, smaritalstatus, address, email, phone,
    countryoforigin, lgaoforigin, townoforigin, occupation,
    slevelofeducation, spouse, spousenames, spouseaddress,
    dependants, naturalgroup, cell, lovecenter, departments,
    programs
  } = userDetail;
  const classes = useStyles();

  const sdob = getFormattedDate(dob);
  const fNames = `${lastname} ${firstname} ${othernames}`;
  const fAddress = address && `${address?.desc}\n${address?.location.town}\n${address?.location?.lga?.name}\n${address?.location?.lga?.state?.name}\n${address?.location?.lga?.state?.country?.name}`;
  const renderData = [
    { title: 'Full Names', desc: fNames, icon: <BusinessIcon /> },
    { title: 'Status', desc: sstatus, icon: <CakeIcon /> },
    { title: 'Member ID', desc: memberid, icon: <SchoolIcon /> },
    { title: 'Date of Birth', desc: sdob, icon: <LocationOnIcon /> },
    { title: 'Gender', desc: sgender, icon: <BusinessIcon /> },
    { title: 'Marital Status', desc: smaritalstatus, icon: <CakeIcon /> },
    { title: 'Email', desc: email, icon: <SchoolIcon /> },
    { title: 'Phone', desc: phone, icon: <LocationOnIcon /> },
    { title: 'Address', desc: fAddress, icon: <LocationOnIcon /> }, 
    { title: 'Level of Education', desc: slevelofeducation, icon: <LocationOnIcon /> }, 
    { title: 'Occupation', desc: occupation, icon: <LocationOnIcon /> }, 
  ];

  if (countryoforigin)
    renderData.push({ title: 'Country of Origin', desc: countryoforigin.name, icon: <LocationOnIcon /> });

  if (lgaoforigin) 
  {
    renderData.push({ title: 'Town of Origin', desc: townoforigin, icon: <LocationOnIcon /> })
    renderData.push({ title: 'LGA of Origin', desc: lgaoforigin.name, icon: <LocationOnIcon /> })
    renderData.push({ title: 'State of Origin', desc: lgaoforigin.state.name, icon: <LocationOnIcon /> })
    renderData.push({ title: 'Country of Origin', desc: lgaoforigin.state.country.name, icon: <LocationOnIcon /> })
  }

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader
        separator={{
          color: theme.palette.borderColor.dark,
        }}
        title="Bio Data">
      </CmtCardHeader>
      <CmtCardContent>
        <CmtGridView
          itemPadding={24}
          responsive={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
          }}
          data={renderData}
          renderRow={(item, index) => (
            <Box display="flex" alignItems="center" key={index}>
              {item.icon}
              <Box ml={6}>
                <Box fontSize={12} color="text.secondary">
                  {item.title}
                </Box>
                <Box className={classes.columnRoot}>{item.desc}</Box>
              </Box>
            </Box>
          )}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default BioData;

BioData.prototype = {
  userDetail: PropTypes.object.isRequired,
};
