import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
//import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useSelector } from 'react-redux';
import CmtList from '../../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';


const DetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { selectedItem } = useSelector(({ members }) => members);

  const { memberid, stitle, lastname, firstname, othernames, sgender, smaritalstatus, sstatus, naturalgroup, address } = selectedItem;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.infoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>
                {stitle} {lastname} {firstname} {othernames}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Member ID
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {memberid}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Names (Surname first)
            </Box>
            <Box ml={2} component="p" fontSize={16}>
            {stitle} {lastname} {firstname} {othernames}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Gender
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {sgender}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Marital Status
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {smaritalstatus}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Membership Status
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {sstatus}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Natural Group
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {naturalgroup?.name}
            </Box>
          </Box>
        </Box>
        {address && (
        <>
        <Divider />
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Address
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {address?.desc}
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {address?.location?.lga?.name}
            </Box>            
            <Box ml={2} component="p" fontSize={16}>
              {address?.location?.town}
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {address?.location?.lga?.state?.name}
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {address?.location?.lga?.state?.country?.name}
            </Box>
          </Box>
        </Box>
        </>
        )}
      </Box>
    </Dialog>
  );
};

export default DetailView;

DetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
