import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
//import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useSelector } from 'react-redux';
import CmtList from '../../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';

const DetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { selectedItem } = useSelector(({ naturalGroups }) => naturalGroups);

  const { name, description, head, secretary, treasurer, sstatus, datecreated, dateupdated } = selectedItem;

  const createdOn = moment(datecreated).format("dddd, MMM Do YYYY, h:mm:ss a");
  const updatedAt = moment(dateupdated).format("dddd, MMM Do YYYY, h:mm:ss a");

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.infoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>
                {name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Name
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {name}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Description
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {description}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              President
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {head?.lastname} {head?.firstname} {head?.othernames}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Secretary
            </Box>
            <Box ml={2} component="p" fontSize={16}>
            {secretary?.lastname} {secretary?.firstname} {secretary?.othernames}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Treasurer
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {treasurer?.lastname} {treasurer?.firstname} {treasurer?.othernames}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Status
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {sstatus}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Date Created
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {createdOn}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Date Updated
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {updatedAt}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DetailView;

DetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
