import IntlMessages from '../../../../utils/IntlMessages';
import actions from './actions'
import main from './main'
import management from './management'
import members from './members'
//import settings from './settings'
//import users from './users'
import advancement from './advancement'

import React from 'react';

// ** Merge & Export
export default [
    {
        name: <IntlMessages id={'sidebar.dashboard'} />,
        type: 'collapse',
        children: main,
        roles: ["SU"]
    },
    {
        name: <IntlMessages id={'sidebar.members'} />,
        type: 'collapse',
        children: members,
        roles: ["SU"] 
    },
    {
        name: <IntlMessages id={'sidebar.management'} />,
        type: 'collapse',
        children: management,
        roles: ["SU"]
    },
    // {
    //     name: <IntlMessages id={'sidebar.settings'} />,
    //     type: 'collapse',
    //     children: settings,
    //     roles: ["SU"]
    // },
    // {
    //     name: <IntlMessages id={'sidebar.users'} />,
    //     type: 'collapse',
    //     children: users,
    //     roles: ["SU"]
    // },
    {
        name: <IntlMessages id={'sidebar.advancement'} />,
        type: 'collapse',
        children: advancement,
        roles: ["SU"]
    },
    {
        name: <IntlMessages id={'sidebar.actions'} />,
        type: 'collapse',
        children: actions,
        roles: ["SU"]
    }
]