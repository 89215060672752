import React from 'react';
import { Box } from '@material-ui/core';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LinkIcon from '@material-ui/icons/Link';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import blue from '@material-ui/core/colors/blue';
import { geValidUrl } from '../../../../@jumbo/utils/commonHelper';

const useStyles = makeStyles(theme => ({
  iconView: {
    backgroundColor: alpha(blue['500'], 0.1),
    color: blue['500'],
    padding: 8,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      display: 'block',
    },
    '&.web': {
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main,
    },
    '&.phone': {
      backgroundColor: alpha(theme.palette.success.main, 0.15),
      color: theme.palette.success.dark,
    },
  },
  wordAddress: {
    wordBreak: 'break-all',
    cursor: 'pointer',
  },
}));

const Spouse = ({ userDetail }) => {
  const classes = useStyles();

  const { spousenames, spouseaddress, spouse, numberofchildren } = userDetail;

  return (
    <CmtCard>
      <CmtCardHeader title="Spouse" />
      <CmtCardContent>
      {!spouse ? (
        <>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <MailOutlineIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12} color="text.secondary">
              Names
            </Box>
            <Box component="span" className={classes.wordAddress} fontSize={16}>
              {spousenames}
            </Box>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <MailOutlineIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12} color="text.secondary">
              Address
            </Box>
            <Box component="span" className={classes.wordAddress} fontSize={16}>
              {spouseaddress}
            </Box>
          </Box>
        </Box>

        {numberofchildren && (
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <MailOutlineIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12} color="text.secondary">
              Number of Children
            </Box>
            <Box component="span" className={classes.wordAddress} fontSize={16}>
              {numberofchildren}
            </Box>
          </Box>
        </Box>
        )}
        </>
      ) : (
        <>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <MailOutlineIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12} color="text.secondary">
              Names
            </Box>
            <Box component="span" className={classes.wordAddress} fontSize={16}>
              {spouse.lastname} {spouse.firstname} {spouse.othernames}
            </Box>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <MailOutlineIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12} color="text.secondary">
              Member ID
            </Box>
            <Box component="span" className={classes.wordAddress} fontSize={16}>
              {spouse.memberid}
            </Box>
          </Box>
        </Box>
        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <Box className={classes.iconView}>
            <MailOutlineIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12} color="text.secondary">
              Status
            </Box>
            <Box component="span" className={classes.wordAddress} fontSize={16}>
              {spouse.sstatus}
            </Box>
          </Box>
        </Box>
        </>
      )}
      </CmtCardContent>
    </CmtCard>
  );
};

export default Spouse;

Spouse.prototype = {
  userDetail: PropTypes.object.isRequired,
};
