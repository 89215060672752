import IntlMessages from '../../../../utils/IntlMessages';
import {
  LibraryBooks,
} from '@material-ui/icons';
import React from 'react';

export default [
  {
    name: <IntlMessages id={'sidebar.dashboard'} />,
    icon: <LibraryBooks />,
    type: 'item',
    link: '/dashboard',
    roles: ["SU"]
  }
]