import axios from 'axios';


const customAxios = axios.create({
  //baseUrl: 'https://royalharvestapi.azurewebsites.net/api/',
  baseURL: process.env.NODE_ENV !== "production" ? `https://91rsxc97-5001.uks1.devtunnels.ms/api/` : `https://api.rhac.org.ng/api/`, 
  //baseURL: "https://api.royalha.org/api/",
  //timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
})


const responseHandler = response => {
  if (response.status === 401) {
    window.location = '/signin';
  }

  return response;
};
 
const errorHandler = error => {
  return Promise.reject(error);
}

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
)

export default customAxios;


// export default axios.create({
//   baseURL: `https://localhost:5001/api/`, //YOUR_API_URL HERE
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });
