import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../../@coremat/CmtList';   // '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { isValidEmail, isValidHttpUrl } from '../../../../../@jumbo/utils/commonHelper';
import { getBusinessData } from '../store/action';
import { addData, updateData } from '../../../advancement/business/store/action';
import AddressModel from '../../../../../components/AddressModel';
import Skeleton from '@material-ui/lab/Skeleton';
import ChipInput from 'material-ui-chip-input'
//import Notifications, { createNotification } from '../../../../../components/extensions/Notifications';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));



const BusinessAddEditDialog = ({ open, onCloseDialog, onAddSuccess, onAddFailed, onUpdateSuccess, onUpdateFailed }) => {
  const classes = useStyles();

  const { selectedItem, business, isFetching } = useSelector(({ members }) => members);
  const { finish } = useSelector(({ businesses }) => businesses);

  const [memberId, setMemberId] = useState(selectedItem?.id);

  const [businessname, setBusinessName] = useState(business?.businessname);
  const [businessnameError, setBusinessNameError] = useState('');

  const [description, setDescription] = useState(business?.description);
  const [descriptionError, setDescriptionError] = useState('');

  const [whatYouDo, setWhatYouDo] = useState(business?.whatyoudo);
  const [whatYouDoError, setWhatYouDoError] = useState('');

  const [email, setEmail] = useState(business?.email);
  const [emailError, setEmailError] = useState('');

  const [phoneNo, setPhoneNo] = useState(business?.phoneno);
  const [phoneNoError, setPhoneNoError] = useState('');

  const [website, setWebsite] = useState(business?.website);
  const [websiteError, setWebsiteError] = useState('');

  const [tags, setTags] = useState(business?.btags ?? []);
  const [tagsError, setTagsError] = useState('');
 
  const [address, setAddress] = useState(business?.address);
  const [addressError, setAddressError] = useState('');

  const [currentMember, setCurrentMember] = useState(selectedItem);
  const [currentBusiness, setCurrentBusiness] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedItem?.firstbusinesslistingid) {
      dispatch(getBusinessData(selectedItem?.firstbusinesslistingid, (data) => {

        setBusinessName(data?.businessname);
        setDescription(data?.description);
        setWhatYouDo(data?.whatyoudo);
        setEmail(data?.email);
        setPhoneNo(data?.phoneno);
        setWebsite(data?.website);
        setTags(data?.btags);
        setAddress(data?.address);

        setCurrentBusiness(data);
      }, () => {}));
    }
    setCurrentMember(selectedItem);
  }, []);

  const onSubmitClick = () => {

    if (!businessname) {
      setBusinessNameError(requiredMessage);
    }     
    if (!whatYouDo) {
      setWhatYouDoError(requiredMessage);
    }  
    if (!phoneNo) {
      setPhoneNoError(requiredMessage);
    } 
    if (!tags) {
      setTagsError("Enter atleast one tag!");
    }
    if (!address?.lgaid) {
      setAddressError(requiredMessage);
    }
    if (email & !isValidEmail(email)) {
      setEmailError("Enter a valid email!");
    }
    if (website & isValidHttpUrl(website)) {
      setWebsiteError("Enter a valid website!");
    }

    //console.log('got here 0', businessname, tags, (email & isValidEmail(email)), (website & isValidHttpUrl(website)))
    if (businessname && whatYouDo && phoneNo && tags) {   // && (email & isValidEmail(email)) && (website & isValidHttpUrl(website))) {
      onBusinessSave();
    }
  };

  const onBusinessSave = () => {
    // let addr = null;
    // if (address) {
    //   addr = {
    //     desc: address.desc,
    //     landmark: address.landmark,
    //     location: {
    //       town: address.location?.town,
    //       lgaid: parseInt(address.location?.lgaid)
    //     }
    //   };
    // }
    const newBusiness = {
      memberid: memberId,
      businessname: businessname,
      description: description,
      whatyoudo: whatYouDo,
      email: email,
      phoneno: phoneNo,
      desc: address.desc,
      landmark: address.landmark,
      town: address.location?.town,
      lgaid: parseInt(address.location?.lgaid),
      // address: addr,
      website: website,
      btags: tags[tags.length - 1]
    };

    if (currentBusiness) {
      dispatch(
        updateData({ ...currentBusiness, ...newBusiness }, () => {
          if (onUpdateSuccess)
            onUpdateSuccess();
          //createNotification("success", "Updated Successfully!");
          onCloseDialog();
        }, (err) => {
          if (onUpdateFailed)
            onUpdateFailed(err);
          //createNotification('error', `Could not update!,\n${err}`);
        }),
      );
    } else {
      dispatch(
        addData(newBusiness, () => {
          if (onAddSuccess)
            onAddSuccess();
          //createNotification("success", "Created Successfully!");
          onCloseDialog();
        }, (err) => {
          if (onAddFailed)
            onAddFailed(err);
          //createNotification('error', `Could not create!,\n${err}`);
        }),
      );
    }
  };

  const onBeforeAdd = (chip) => {
    return chip.length >= 3
  }

  const handleAdd = (tag) => {   
    setTags([...tags, tag])
  }

  const handleDelete = (deletedChip) => {
    setTags(tags.filter(c => c != deletedChip))
  }

  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{currentBusiness ? 'Edit Business' : 'Create New Business'}</DialogTitle>
        <DialogContent dividers>
         {isFetching ? (
          <>
            <Skeleton animation="wave" variant="rect" />
            <Skeleton animation="wave" variant="rect" />
          </>
         ) : (
          <>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={3}>
                <Box ml={2} component="p" fontSize={16}>
                  Member
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box ml={2} component="p" fontSize={16}>
                  {currentMember?.lastname} {currentMember?.firstname} {currentMember?.othernames}
                </Box>
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Business Name"
                  value={businessname}
                  onChange={e => {
                    setBusinessName(e.target.value);
                    setBusinessNameError('');
                  }}
                  helperText={businessnameError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  multiline={true}
                  rows={3}
                  variant="outlined"
                  label="Description"
                  value={description}
                  onChange={e => {
                    setDescription(e.target.value);
                    setDescriptionError('');
                  }}
                  helperText={descriptionError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  multiline={true}
                  rows={4}
                  variant="outlined"
                  label="What you do"
                  value={whatYouDo}
                  onChange={e => {
                    setWhatYouDo(e.target.value);
                    setWhatYouDoError('');
                  }}
                  helperText={whatYouDoError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailError('');
                  }}
                  helperText={emailError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Phone Number"
                  value={phoneNo}
                  onChange={e => {
                    setPhoneNo(e.target.value);
                    setPhoneNoError('');
                  }}
                  helperText={phoneNoError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Website"
                  value={website}
                  onChange={e => {
                    setWebsite(e.target.value);
                    setWebsiteError('');
                  }}
                  helperText={websiteError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AddressModel address={address}
                        setAddress={setAddress} />
              </Grid>
            </GridContainer>
          </Box>  
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <ChipInput
                  defaultValue={tags}
                  onBeforeAdd={(chip) => onBeforeAdd(chip)}
                  onAdd={(chip) => handleAdd(chip)}
                  onDelete={(deletedChip) => handleDelete(deletedChip)}
                  onChange={(chip) => handleAdd(chip)}
                  // onBlur={(event) => {
                  //   if (event.target.value) {
                  //       handleAdd(event.target.value)
                  //   }
                  // }}
                  variant="outlined"
                  fullWidth
                  label='Tags'
                  helperText={tagsError}
                />
              </Grid>
            </GridContainer>
          </Box>  

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button disabled={!finish} variant="contained" color="primary" onClick={onSubmitClick}>
                Save
              </Button>
            </Box>
          </Box>
          </>
          )}
        </DialogContent>
      </Dialog>
  );
};

export default BusinessAddEditDialog;

BusinessAddEditDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
