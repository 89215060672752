import React from 'react';
import { Box } from '@material-ui/core';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import CmtGridView from '../../../../@coremat/CmtGridView';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
//import MailOutlineIcon from '@material-ui/icons/MailOutline';
//import LinkIcon from '@material-ui/icons/Link';
//import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import blue from '@material-ui/core/colors/blue';
//import { geValidUrl } from '../../../../@jumbo/utils/commonHelper';

const useStyles = makeStyles(theme => ({
      textUppercase: {
        textTransform: 'uppercase',
      },
      thumbRoot: {
        position: 'relative',
        borderRadius: 4,
        overflow: 'hidden',
        cursor: 'pointer',
        '& img': {
          width: '100%',
          height: '100%',
          display: 'block',
        },
      },
      thumbContent: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1,
        width: '100%',
        height: '100%',
        backgroundImage: 'linear-gradient(180deg,rgba(0,0,0,.01) 0,rgba(0,0,0,.65))',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: 8,
      },
      dotRoot: {
        position: 'absolute',
        right: 10,
        top: 10,
        zIndex: 1,
        width: 14,
        height: 14,
        border: `solid 2px ${theme.palette.common.white}`,
        borderRadius: '50%',
      },
      thumbTitle: {
        fontSize: 12,
        letterSpacing: 0.4,
        color: theme.palette.text.secondary,
        marginLeft: 12,
        paddingLeft: 12,
        //color: theme.palette.common.white,
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
      },
}));

const Roles = ({ authUser }) => {
  const { roles } = authUser;
  const classes = useStyles();

  return (
    <CmtCard>
      <CmtCardHeader title="Roles" />
      <CmtCardContent>
        <ol>
        { roles.map((role, index) => {
            return (
              <Box key={role} component="li" className={classes.thumbTitle}>{role}</Box>
            )
        })}
        </ol>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Roles;

Roles.prototype = {
  authUser: PropTypes.object.isRequired,
};
