// ** Initial State
import {
  SET_DATUM_DETAILS,
  GET_DATA_FETCHING,
  GET_COUNT_FETCHING,
  GET_COUNT_SUCCESS,
  GET_COUNT_FAILED,
  GET_ALL_DATA_SUCCESS,
  GET_PAGED_DATA_SUCCESS,
  GET_DATA_FAILED,
  GET_SELECT_LIST_DATA_FETCHING,
  GET_SELECT_LIST_DATA_SUCCESS,
  GET_SELECT_LIST_DATA_FAILED,
  GET_DATUM_FETCHING,
  GET_DATUM_SUCCESS,
  GET_DATUM_FAILED,
  ADD_DATUM_FETCHING,
  ADD_DATUM_SUCCESS,
  ADD_DATUM_FAILED,
  UPDATE_DATUM_FETCHING,
  UPDATE_DATUM_SUCCESS,
  UPDATE_DATUM_FAILED,
  DELETE_DATUM_FETCHING,
  DELETE_DATUM_SUCCESS,
  DELETE_DATUM_FAILED,
  DELETE_DATA_FETCHING,
  DELETE_DATA_SUCCESS,
  DELETE_DATA_FAILED,

  GET_SELECT_TITLE_DATA_FETCHING,
  GET_SELECT_TITLE_DATA_SUCCESS,
  GET_SELECT_TITLE_DATA_FAILED,
  GET_SELECT_GENDER_DATA_FETCHING,
  GET_SELECT_GENDER_DATA_SUCCESS,
  GET_SELECT_GENDER_DATA_FAILED,
  GET_SELECT_MARITAL_STATUS_DATA_FETCHING,
  GET_SELECT_MARITAL_STATUS_DATA_SUCCESS,
  GET_SELECT_MARITAL_STATUS_DATA_FAILED,
  GET_SELECT_MEMBERSHIP_STATUS_DATA_FETCHING,
  GET_SELECT_MEMBERSHIP_STATUS_DATA_SUCCESS,
  GET_SELECT_MEMBERSHIP_STATUS_DATA_FAILED,
  GET_SELECT_EDUCATION_LEVEL_DATA_FETCHING,
  GET_SELECT_EDUCATION_LEVEL_DATA_SUCCESS,
  GET_SELECT_EDUCATION_LEVEL_DATA_FAILED,
  GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_FETCHING,
  GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_SUCCESS,
  GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_FAILED,
  GET_SELECT_COUNTRY_CODES_DATA_FETCHING,
  GET_SELECT_COUNTRY_CODES_DATA_SUCCESS,
  GET_SELECT_COUNTRY_CODES_DATA_FAILED
} from '../type'

const initialState = {
  count: null,
  isCountLoading: false,
  countError: null,
  allData: [],
  data: [],
  total: 0,
  params: {},
  items: [],
  selectedItem: null,
  business: null,
  isFetching: false,
  finish: true,   // for add, update, delete
  error: null,
  titles: [],
  genders: [],
  maritalStatuses: [],
  statuses: [],
  justifications: [],
  countryCodes: [],
  eduLevels: []
}

const firstTimers = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATUM_DETAILS:
      return { ...state, selectedItem: action.payload, isFetching: false, error: null }
    case GET_COUNT_FETCHING:
      return { ...state, isCountLoading: true, countError: null }
    case GET_COUNT_SUCCESS:
      return { ...state, count: action.payload.data, isCountLoading: false, countError: null }
    case GET_COUNT_FAILED:
      return {
        ...state,
        isCountLoading: false,
        countError: action.payload.error
      }
    case GET_DATA_FETCHING:
      return { ...state, isFetching: true, error: null }
    case GET_ALL_DATA_SUCCESS:
      return { ...state, allData: action.payload.data, isFetching: false, error: null }
    case GET_PAGED_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.totalPages,
        params: action.payload.params,
        isFetching: false,
        error: null
      }
    case GET_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }
    case GET_DATUM_FETCHING:
      return { ...state, isFetching: true, error: null }
    case GET_DATUM_SUCCESS:
      return { ...state, selectedItem: action.payload.selectedItem, isFetching: false, error: null }
    case GET_DATUM_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }   
    case GET_SELECT_LIST_DATA_FETCHING:
      return { ...state, isFetching: true, error: null }
    case GET_SELECT_LIST_DATA_SUCCESS:
      return { 
        ...state, 
        items: action.payload.data,
        isFetching: false, 
        error: null 
      }
    case GET_SELECT_LIST_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }
    case ADD_DATUM_FETCHING:
      return { ...state, finish: false, error: null }      
    case ADD_DATUM_SUCCESS:
      return { ...state, finish: true, error: null }
    case ADD_DATUM_FAILED:
      return {
        ...state,
        finish: true,
        error: action.payload.error
      }
    case UPDATE_DATUM_FETCHING:
      return { ...state, finish: false, error: null }
    case UPDATE_DATUM_SUCCESS:
      return { ...state, finish: true, error: null }
    case UPDATE_DATUM_FAILED:
      return {
        ...state,
        finish: true,
        error: action.payload.error
      }
    case DELETE_DATUM_FETCHING:
      return { ...state, finish: false, error: null }
    case DELETE_DATUM_SUCCESS:
      return { ...state, finish: true, error: null }
    case DELETE_DATUM_FAILED:
      return {
        ...state,
        finish: true,
        error: action.payload.error
      }
    case DELETE_DATA_FETCHING:
      return { ...state, finish: false, error: null }
    case DELETE_DATA_SUCCESS:
      return { ...state, finish: true, error: null }
    case DELETE_DATA_FAILED:
      return {
        ...state,
        finish: true,
        error: action.payload.error
      }
    case GET_SELECT_TITLE_DATA_FETCHING:
      return { ...state, isFetching: true, error: null }
    case GET_SELECT_TITLE_DATA_SUCCESS:
      return { 
        ...state, 
        titles: action.payload.data,
        isFetching: false, 
        error: null 
      }
    case GET_SELECT_TITLE_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }
    case GET_SELECT_GENDER_DATA_FETCHING:
      return { ...state, isFetching: true, error: null }
    case GET_SELECT_GENDER_DATA_SUCCESS:
      return { 
        ...state, 
        genders: action.payload.data,
        isFetching: false, 
        error: null 
      }
    case GET_SELECT_GENDER_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }  
    case GET_SELECT_MARITAL_STATUS_DATA_FETCHING:
      return { ...state, isFetching: true, error: null }
    case GET_SELECT_MARITAL_STATUS_DATA_SUCCESS:
      return { 
        ...state, 
        maritalStatuses: action.payload.data,
        isFetching: false, 
        error: null 
      }
    case GET_SELECT_MARITAL_STATUS_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }  
    case GET_SELECT_MEMBERSHIP_STATUS_DATA_FETCHING:
      return { ...state, isFetching: true, error: null }
    case GET_SELECT_MEMBERSHIP_STATUS_DATA_SUCCESS:
      return { 
        ...state, 
        statuses: action.payload.data,
        isFetching: false, 
        error: null 
      }
    case GET_SELECT_MEMBERSHIP_STATUS_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      } 
    case GET_SELECT_EDUCATION_LEVEL_DATA_FETCHING:
      return { ...state, isFetching: true, error: null }
    case GET_SELECT_EDUCATION_LEVEL_DATA_SUCCESS:
      return { 
        ...state, 
        eduLevels: action.payload.data,
        isFetching: false, 
        error: null 
      }
    case GET_SELECT_EDUCATION_LEVEL_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      } 
    case GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_FETCHING:
      return { ...state, isFetching: true, error: null }
    case GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_SUCCESS:
      return { 
        ...state, 
        justifications: action.payload.data,
        isFetching: false, 
        error: null 
      }
    case GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }   
    case GET_SELECT_COUNTRY_CODES_DATA_FETCHING:
      return { ...state, isFetching: true, error: null }
    case GET_SELECT_COUNTRY_CODES_DATA_SUCCESS:
      return { 
        ...state, 
        countryCodes: action.payload.data,
        isFetching: false, 
        error: null 
      }
    case GET_SELECT_COUNTRY_CODES_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }                                
    default:
      return { ...state }
  }
}
export default firstTimers
