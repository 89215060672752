import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../../@coremat/CmtList';   // '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import LocationModel from '../../../../../components/LocationModel';
//import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { 
  getData, 
  addData, 
  updateTeamLead,
  teamLeadSelectData,
  memberSelectData
} from '../store/action';    


const useStyles = makeStyles(theme => ({
  exclusiveRoot: {
    fontSize: 12,
    color: theme.palette.error
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));



const AddEditTeamLeadDialog = ({ open, onCloseDialog, onAddSuccess, onAddFailed, onUpdateSuccess, onUpdateFailed }) => {
  const classes = useStyles();

  const { selectedItem, ruleSets, teamLeads, members, finish } = useSelector(({ ftmgmtgroups }) => ftmgmtgroups);
  //const memberStore = useSelector(state => state.members);

  const [groupNumber, setGroupNumber] = useState(selectedItem?.groupnumber)
  
  const [teamleadId, setTeamleadId] = useState(selectedItem?.teamlead?.id);
  const [teamleadIdError, setTeamleadIdError] = useState('');

  const [exclusiveError, setExclusiveError] = useState('');

  const [currentGroup, setCurrentGroup] = useState(selectedItem);

  const dispatch = useDispatch();

  useEffect(() => {

    setCurrentGroup(selectedItem);

    dispatch(teamLeadSelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))
  }, []);

  const onSubmitClick = () => {
    if (teamleadId) {
      onGroupSave();
    }
  };

  const onGroupSave = () => {
    if (currentGroup) {
      dispatch(
        updateTeamLead({hid: currentGroup.id, id: currentGroup.tid, teamLeadId: teamleadId }, () => {
          if (onUpdateSuccess)
            onUpdateSuccess();
          onCloseDialog();
        }, (err) => {
          if (onUpdateFailed)
            onUpdateFailed(err);
        }),
      );
    }
  };

  //console.log(selectedItem)

  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{currentGroup ? 'Edit Team Lead' : 'Add Team Lead'}</DialogTitle>
        <DialogContent dividers>
        {exclusiveError  && (
          <Box display="flex" alignItems="center" mb={{ xs: 6, md: 5 }}>
            <Typography className={classes.exclusiveRoot}>
              {groupNumber}
            </Typography>
          </Box>          
        )}
          {currentGroup && (
            <>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
              <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Team Lead"
                  value={teamleadId}
                  data={teamLeads}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setTeamleadId(e.target.value);
                    setTeamleadIdError('');
                    setExclusiveError('');
                  }}
                  helperText={teamleadIdError}
                />
              </Grid>
            </GridContainer>
          </Box>
          </>
          )}

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button disabled={!finish} variant="contained" color="primary" onClick={onSubmitClick}>
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default AddEditTeamLeadDialog;

AddEditTeamLeadDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
