
export const SET_DATUM_DETAILS = 'SET_PROGRAM_DETAILS'

export const GET_COUNT_FETCHING = 'GET_PROGRAM_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_PROGRAM_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_PROGRAM_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_PROGRAMS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_PROGRAMS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_PROGRAMS_SUCCESS'
export const GET_DATA_FAILED = 'GET_PROGRAMS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_PROGRAMS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_PROGRAMS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_PROGRAMS_FAILED'

export const GET_DATUM_FETCHING = 'GET_PROGRAM_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_PROGRAM_SUCCESS'
export const GET_DATUM_FAILED = 'GET_PROGRAM_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_PROGRAM_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_PROGRAM_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_PROGRAM_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_PROGRAM_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_PROGRAM_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_PROGRAM_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_PROGRAM_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_PROGRAM_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_PROGRAM_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_PROGRAMS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_PROGRAMS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_PROGRAMS_FAILED'
