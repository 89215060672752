
export const SET_DATUM_DETAILS = 'SET_STATE_DETAILS'

export const GET_DATA_FETCHING = 'GET_STATES_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_STATES_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_STATES_SUCCESS'
export const GET_DATA_FAILED = 'GET_STATES_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_STATES_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_STATES_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_STATES_FAILED'

export const GET_DATUM_FETCHING = 'GET_STATE_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_STATE_SUCCESS'
export const GET_DATUM_FAILED = 'GET_STATE_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_STATE_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_STATE_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_STATE_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_STATE_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_STATE_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_STATE_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_STATE_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_STATE_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_STATE_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_STATES_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_STATES_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_STATES_FAILED'
