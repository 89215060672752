
export const SET_DATUM_DETAILS = 'SET_FT_MGMT_GROUP_DETAILS'

export const GET_COUNT_FETCHING = 'GET_FT_MGMT_GROUP_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_FT_MGMT_GROUP_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_FT_MGMT_GROUP_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_FT_MGMT_GROUPS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_FT_MGMT_GROUPS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_FT_MGMT_GROUPS_SUCCESS'
export const GET_DATA_FAILED = 'GET_FT_MGMT_GROUPS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_FT_MGMT_GROUPS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_FT_MGMT_GROUPS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_FT_MGMT_GROUPS_FAILED'

export const GET_SELECT_MEMBERS_DATA_FETCHING = 'GET_SELECT_FT_MGMT_GROUP_MEMBERS_FETCHING'
export const GET_SELECT_MEMBERS_DATA_SUCCESS = 'GET_SELECT_FT_MGMT_GROUP_MEMBERS_SUCCESS'
export const GET_SELECT_MEMBERS_DATA_FAILED = 'GET_SELECT_FT_MGMT_GROUP_MEMBERS_FAILED'

export const GET_SELECT_TEAM_LEADS_DATA_FETCHING = 'GET_SELECT_FT_MGMT_GROUP_TEAM_LEADS_FETCHING'
export const GET_SELECT_TEAM_LEADS_DATA_SUCCESS = 'GET_SELECT_FT_MGMT_GROUP_TEAM_LEADS_SUCCESS'
export const GET_SELECT_TEAM_LEADS_DATA_FAILED = 'GET_SELECT_FT_MGMT_GROUP_TEAM_LEADS_FAILED'

export const GET_DATUM_FETCHING = 'GET_FT_MGMT_GROUP_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_FT_MGMT_GROUP_SUCCESS'
export const GET_DATUM_FAILED = 'GET_FT_MGMT_GROUP_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_FT_MGMT_GROUP_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_FT_MGMT_GROUP_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_FT_MGMT_GROUP_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_FT_MGMT_GROUP_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_FT_MGMT_GROUP_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_FT_MGMT_GROUP_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_FT_MGMT_GROUP_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_FT_MGMT_GROUP_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_FT_MGMT_GROUP_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_FT_MGMT_GROUPS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_FT_MGMT_GROUPS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_FT_MGMT_GROUPS_FAILED'

export const REMOVE_TEAM_LEAD_FETCHING = 'REMOVE_TEAM_LEAD_FETCHING'
export const REMOVE_TEAM_LEAD_SUCCESS = 'REMOVE_TEAM_LEAD_SUCCESS'
export const REMOVE_TEAM_LEAD_FAILED = 'REMOVE_TEAM_LEAD_FAILED'

export const REMOVE_MEMBER_FETCHING = 'REMOVE_MEMBER_FETCHING'
export const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS'
export const REMOVE_MEMBER_FAILED = 'REMOVE_MEMBER_FAILED'