import IntlMessages from '../../../../utils/IntlMessages';
import {
  // AccountCircle,
  // ArrowForward,
  // Category,
  // Chat,
  // CheckCircle,
  // CloudUpload,
  // Colorize,
  // ContactMail,
  // Contacts,
  // Domain,
  // DonutSmall,
  // DragIndicator,
  // Edit,
  // EditAttributes,
  // Email,
  // Error,
  // Event,
  // Group,
  // ImportContacts,
  // InsertChart,
  // LibraryBooks,
  // LocalGroceryStore,
  // LockOutlined,
  // Map,
  // MonetizationOn,
  // NotificationImportant,
  // Notifications,
  // Pages,
  People,
  // ShowChart,
  // Timeline,
  // VpnKey,
  // Widgets,
  ControlPoint,
  List
} from '@material-ui/icons';
import React from 'react';

export default  [
  {
    name: <IntlMessages id={'sidebar.firstTimers.report'} />,
    type: 'item',
    icon: <People />,
    roles: ["SU"],
    link: '/actions/first-timers/report'
  },
  // {
  //   name: <IntlMessages id={'sidebar.newComers.report'} />,
  //   type: 'item',
  //   icon: <People />,
  //   roles: ["SU"],
  //   link: '/actions/new-comers/report'
  // },
  // {
  //   name: <IntlMessages id={'sidebar.deliverables'} />,
  //   type: 'collapse',
  //   roles: ["SU"],
  //   children: [
  //     {
  //       name: <IntlMessages id={'sidebar.deliverables.list'} />,
  //       type: 'item',
  //       icon: <List />,
  //       roles: ["SU"],
  //       link: '/actions/deliverables/list'
  //     },
  //     {
  //       name: <IntlMessages id={'sidebar.deliverables.new'} />,
  //       type: 'item',
  //       icon: <ControlPoint />,
  //       roles: ["SU"],
  //       link: '/actions/deliverables/new'
  //     },
  //   ],
  // },
  // {
  //   name: <IntlMessages id={'sidebar.prayers'} />,
  //   type: 'collapse',
  //   roles: ["SU"],
  //   children: [
  //     {
  //       name: <IntlMessages id={'sidebar.prayers.list'} />,
  //       type: 'item',
  //       icon: <List />,
  //       roles: ["SU"],
  //       link: '/actions/prayers/list'
  //     },
  //     {
  //       name: <IntlMessages id={'sidebar.prayers.new'} />,
  //       type: 'item',
  //       icon: <ControlPoint />,
  //       roles: ["SU"],
  //       link: '/actions/prayers/new'
  //     },
  //   ],
  // },
  {
    name: <IntlMessages id={'sidebar.programs'} />,
    type: 'collapse',
    roles: ["SU"],
    children: [
      {
        name: <IntlMessages id={'sidebar.programs.list'} />,
        type: 'item',
        icon: <List />,
        roles: ["SU"],
        link: '/actions/programs/list'
      },
      // {
      //   name: <IntlMessages id={'sidebar.programs.new'} />,
      //   type: 'item',
      //   icon: <ControlPoint />,
      //   roles: ["SU"],
      //   link: '/actions/programs/new'
      // },
    ],
  },
  {
    name: <IntlMessages id={'sidebar.users'} />,
    type: 'collapse',
    roles: ["SU"],
    children: [
      {
        name: <IntlMessages id={'sidebar.users.admins.list'} />,
        type: 'item',
        icon: <People />,
        roles: ["SU"],
        link: '/users/admins/list'
      },   
      {
        name: <IntlMessages id={'sidebar.users.members.list'} />,
        type: 'item',
        icon: <People />,
        roles: ["SU"],
        link: '/users/members/list'
      },
      {
        name: <IntlMessages id={'sidebar.users.limited.list'} />,
        type: 'item',
        icon: <People />,
        roles: ["SU"],
        link: '/users/limited-admins/list'
      },
      {
        name: <IntlMessages id={'sidebar.users.general.list'} />,
        type: 'item',
        icon: <People />,
        roles: ["SU"],
        link: '/users/general-admins/list'
      },
      {
        name: <IntlMessages id={'sidebar.users.super.list'} />,
        type: 'item',
        icon: <People />,
        roles: ["SU"],
        link: '/users/supers-admins/list'
      },
      // {
      //   name: <IntlMessages id={'sidebar.users.su.list'} />,
      //   type: 'item',
      //   icon: <People />,
      //   roles: ["SU"],
      //   link: '/users/sus/list'
      // },
    ],
  },
]
