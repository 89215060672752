
export const SET_DATUM_DETAILS = 'SET_DEPARTMENT_DETAILS'

export const GET_COUNT_FETCHING = 'GET_DEPARTMENT_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_DEPARTMENT_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_DEPARTMENT_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_DEPARTMENTS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_DEPARTMENTS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_DEPARTMENTS_SUCCESS'
export const GET_DATA_FAILED = 'GET_DEPARTMENTS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_DEPARTMENTS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_DEPARTMENTS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_DEPARTMENTS_FAILED'

export const GET_SELECT_MEMBERS_DATA_FETCHING = 'GET_SELECT_D_MEMBERS_FETCHING'
export const GET_SELECT_MEMBERS_DATA_SUCCESS = 'GET_SELECT_D_MEMBERS_SUCCESS'
export const GET_SELECT_MEMBERS_DATA_FAILED = 'GET_SELECT_D_MEMBERS_FAILED'

export const GET_DATUM_FETCHING = 'GET_DEPARTMENT_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_DEPARTMENT_SUCCESS'
export const GET_DATUM_FAILED = 'GET_DEPARTMENT_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_DEPARTMENT_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_DEPARTMENT_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_DEPARTMENT_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_DEPARTMENT_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_DEPARTMENT_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_DEPARTMENT_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_DEPARTMENT_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_DEPARTMENTS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_DEPARTMENTS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_DEPARTMENTS_FAILED'
