import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow } from '../../../../../@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../../@coremat/CmtDropdownMenu';   // '../../../../@coremat/CmtDropdownMenu';
import CmtList from '../../../../../@coremat/CmtList';
//import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { Box, Typography } from '@material-ui/core';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
//import { sentMailToUser, updateUserStatus } from '../../../../redux/actions/Users';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
  tlRoot: {
    color: theme.palette.common.dark,
    fontStyle: 'italic'
  }
}));

const getActions = row => {
  const actions = [
    { action: 'view', label: 'View', icon: <Visibility /> }
  ];

  if (row.teamlead === null) {
    actions.push({ action: 'add_t', label: 'Add Team Lead', icon: <Block /> });
  } else {
    actions.push({ action: 'update_t', label: 'Update Team Lead', icon: <Block /> });
    actions.push({ action: 'delete_t', label: 'Remove Team Lead', icon: <Delete /> });
  }
  actions.push({ action: 'add_m', label: 'Add Member', icon: <Delete /> });
  return actions;
};

const getSubActions = item => {
  const actions = [];

  if (item.firsttimers?.length > 0) {
    actions.push({ action: 'move_jcf', label: 'Transfer JCFs', icon: <Edit />})
  } else {
    actions.push({ action: 'remove_m', label: 'Remove', icon: <Delete /> });
  }

  return actions;
}

const MemberRowMenu = ({ row, onTransferFirstTimers, onRemoveMember, TriggerComponent }) => {

  const onSubMenuClick = menu => {
    if (menu.action === 'move_jcf') {
      onTransferFirstTimers(row);
    } else if (menu.action === 'remove_m') {
      onRemoveMember(row);
    }
  } 

  const menuActions = getSubActions(row); 

  return (
    <CmtDropdownMenu items={menuActions} onItemClick={onSubMenuClick} TriggerComponent={TriggerComponent} />
  )
}

const ListRow = ({ row, isSelected, onRowClick, onUpdateTeamLead, onAddMember, onView, onRemoveTeamLead, onRemoveMember, onTransferFirstTimers }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onMenuClick = menu => {
    if (menu.action === 'view') {
      onView(row);
    } else if (menu.action === 'add_t' || menu.action === 'update_t') {
      onUpdateTeamLead(row);
    }
    else if (menu.action === 'delete_t') {
      onRemoveTeamLead(row);
    }
    else if (menu.action === 'add_m') {
      onAddMember(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  const menuActions = getActions(row);

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}>
      <TableCell padding="checkbox">
        <></>
        {/* <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} /> */}
      </TableCell>
      <TableCell id={labelId} scope="row" padding="none">
        <Box display="flex" alignItems="center">
          <div>
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {row.groupnumber}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>
        {row.teamlead ? (
          <Box component="p">
            {row.teamlead?.lastname} {row.teamlead?.firstname} {row.teamlead?.othernames}
          </Box>  
        ) : (
          <Typography component="p">
            {"-- NO TEAM LEAD --"}
          </Typography>
        )}    
      </TableCell>
      <TableCell>
       {row.members && row.members.length > 0 ? (
        <CmtList
          data={row.members}
          renderRow={(item, index) => (
            <GridContainer style={{ marginBottom: 2 }} key={index}>
              <Grid item xs={12} sm={12} onClick={event => event.stopPropagation()}>

                  <MemberRowMenu row={item} 
                    onRemoveMember={onRemoveMember} 
                    onTransferFirstTimers={onTransferFirstTimers}
                    TriggerComponent={
                      <Box component="p">
                        {item.member?.title} {item.member?.lastname} {item.member?.firstname} {item.member?.othernames} {item.firsttimers?.length > 0 ? `(${item.firsttimers?.length})` : ""} {"  "} <MoreHoriz />
                      </Box>
                    }  
                  />
              
              </Grid>
            </GridContainer>
          )}
        />
       ) : (
        <Typography component="p">
          {"-- NO TEAM MEMBER --"}
        </Typography>
       )}      
      </TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={menuActions} onItemClick={onMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(ListRow);
