import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { getFormattedDate } from '../../../../@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';   // '../../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
//import { sentMailToUser, updateUserStatus } from '../../../../redux/actions/Users';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getActions = member => {
  const actions = [
    { action: 'view', label: 'Quick View', icon: <Visibility /> },
    { action: 'edit', label: 'Edit', icon: <Edit /> },
    { action: 'reset', label: 'Reset Password', icon: <CheckCircleOutline /> },
    { action: 'delete', label: 'Delete', icon: <Delete /> }
  ];
  
  return actions;
};

const ListRow = ({ row, isSelected, onRowClick, onPasswordReset, onView, onEdit, onDelete }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onMenuClick = menu => {
    if (menu.action === 'view') {
      onView(row);
    } else if (menu.action === 'reset') {
      onPasswordReset(row);
    } else if (menu.action === 'edit') {
      onEdit(row);
    } else if (menu.action === 'delete') {
      onDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  const menuActions = getActions(row);

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}>
      <TableCell padding="checkbox">
        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell>
      <TableCell>{`${row.lastname} ${row.firstname} ${!!row.othernames ? row.othernames : ''}`}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.srole}</TableCell>
      <TableCell>{getFormattedDate(row.dateupdated, "DD MMM YYYY, hh:mm:ss a")}</TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={menuActions} onItemClick={onMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(ListRow);
