import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridContainer from '../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormHelperText } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    exclusiveRoot: {
      fontSize: 12,
      color: theme.palette.error
    },
    dialogRoot: {
      position: 'relative',
    },
    dialogTitleRoot: {
      '& .MuiTypography-h6': {
        fontSize: 16,
        color: theme.palette.common.dark,
      },
    },
    addressLine: {
      fontSize: 16
    }
  }));


const LocationForm = ({
    location
}) => {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const [loca, setLoc] = useState(location);


    if (loca) {
      return (
        <>
        <Box display="flex" flexDirection="row" alignItems='center'>
          <Box flex={1} component='div'>
            <Typography className={classes.addressLine} color="inherit" variant="subtitle1" component="div">
              {loca?.town}
            </Typography>
            <Typography className={classes.addressLine} color="inherit" variant="subtitle1" component="div">
              {loca?.lga?.state?.name}
            </Typography>
            <Typography className={classes.addressLine} color="inherit" variant="subtitle1" component="div">
              {loca?.lga?.state?.country?.name}
            </Typography>          
          </Box>
        </Box>  
       </>
      )
    }
    else {
      return (
        <>
        </>   
      )
    }

}

export default LocationForm;
