import IntlMessages from '../../../../utils/IntlMessages';
import {
  // AccountCircle,
  // ArrowForward,
  // Category,
  // Chat,
  // CheckCircle,
  // CloudUpload,
  // Colorize,
  // ContactMail,
  // Contacts,
  // Domain,
  // DonutSmall,
  // DragIndicator,
  // Edit,
  // EditAttributes,
  // Email,
  // Error,
  // Event,
  // Group,
  // ImportContacts,
  // InsertChart,
  // LibraryBooks,
  // LocalGroceryStore,
  // LockOutlined,
  // Map,
  // MonetizationOn,
  // NotificationImportant,
  // Notifications,
  // Pages,
  People,
  // ShowChart,
  // Timeline,
  // VpnKey,
  // Widgets,
  ControlPoint,
  List
} from '@material-ui/icons';
import React from 'react';


export default  [
{
    name: <IntlMessages id={'sidebar.jobCategory'} />,
    type: 'collapse',
    roles: ["SU"],
    children: [
    {
        name: <IntlMessages id={'sidebar.jobCategory.list'} />,
        type: 'item',
        icon: <List />,
        roles: ["SU"],
        link: '/advancement/jobcategories'
    },
    {
        name: <IntlMessages id={'sidebar.jobCategory.new'} />,
        type: 'item',
        icon: <ControlPoint />,
        roles: ["SU"],
        link: '/advancement/jobcategories/new'
    },
    ],
},
{
    name: <IntlMessages id={'sidebar.jobCompany'} />,
    type: 'collapse',
    roles: ["SU"],
    children: [
    {
        name: <IntlMessages id={'sidebar.jobCompany.list'} />,
        type: 'item',
        icon: <List />,
        roles: ["SU"],
        link: '/advancement/jobcompanies'
    },
    {
        name: <IntlMessages id={'sidebar.jobCompany.new'} />,
        type: 'item',
        icon: <ControlPoint />,
        roles: ["SU"],
        link: '/advancement/jobcompanies/new'
    },
    ],
},
{
    name: <IntlMessages id={'sidebar.jobs'} />,
    type: 'collapse',
    roles: ["SU"],
    children: [
    {
        name: <IntlMessages id={'sidebar.jobs.list'} />,
        type: 'item',
        icon: <List />,
        roles: ["SU"],
        link: '/advancement/jobs'
    },
    {
        name: <IntlMessages id={'sidebar.jobs.new'} />,
        type: 'item',
        icon: <ControlPoint />,
        roles: ["SU"],
        link: '/advancement/jobs/new'
    },
    ],
},
{
    name: <IntlMessages id={'sidebar.tags'} />,
    type: 'collapse',
    roles: ["SU"],
    children: [
    {
        name: <IntlMessages id={'sidebar.tags.list'} />,
        type: 'item',
        icon: <List />,
        roles: ["SU"],
        link: '/advancement/jobtags'
    },
    {
        name: <IntlMessages id={'sidebar.tags.new'} />,
        type: 'item',
        icon: <ControlPoint />,
        roles: ["SU"],
        link: '/advancement/jobtags/new'
    },
    ],
},
{
    name: <IntlMessages id={'sidebar.listings'} />,
    type: 'collapse',
    roles: ["SU"],
    children: [
    {
        name: <IntlMessages id={'sidebar.listings.list'} />,
        type: 'item',
        icon: <List />,
        roles: ["SU"],
        link: '/advancement/business/listings'
    }
    ],
},
]
