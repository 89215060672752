
export const SET_DATUM_DETAILS = 'SET_ZONE_DETAILS'

export const GET_COUNT_FETCHING = 'GET_ZONE_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_ZONE_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_ZONE_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_ZONES_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_ZONES_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_ZONES_SUCCESS'
export const GET_DATA_FAILED = 'GET_ZONES_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_ZONES_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_ZONES_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_ZONES_FAILED'

export const GET_SELECT_MEMBERS_DATA_FETCHING = 'GET_SELECT_ZONE_MEMBERS_FETCHING'
export const GET_SELECT_MEMBERS_DATA_SUCCESS = 'GET_SELECT_ZONE_MEMBERS_SUCCESS'
export const GET_SELECT_MEMBERS_DATA_FAILED = 'GET_SELECT_ZONE_MEMBERS_FAILED'

export const GET_DATUM_FETCHING = 'GET_ZONE_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_ZONE_SUCCESS'
export const GET_DATUM_FAILED = 'GET_ZONE_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_ZONE_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_ZONE_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_ZONE_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_ZONE_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_ZONE_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_ZONE_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_ZONE_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_ZONE_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_ZONE_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_ZONES_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_ZONES_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_ZONES_FAILED'
