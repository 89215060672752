import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
//import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useSelector } from 'react-redux';
import CmtList from '../../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';

const DetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { selectedItem } = useSelector(({ jobs }) => jobs);

  const { title, description, jobcompany, location, 
    country, category, jtags, applicationwebsite,
    applicationemail, currency, salary, max_salary,
    start_date, end_date, datecreated } = selectedItem;

  const createdOn = moment(datecreated).format("dddd, MMM Do YYYY, h:mm:ss a");
  const startApp = moment(start_date).format('DD MMMM YYYY');
  const closeApp = end_date ? moment(end_date).format('DD MMMM, YYYY') : undefined;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.infoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>
                {title}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              title
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {title}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Description
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {description}
            </Box>
          </Box>
        </Box>        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Company
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {jobcompany?.name}
            </Box>
          </Box>
        </Box>        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Location
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {location}, {country?.name}
            </Box>
          </Box>
        </Box>        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Category
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {category?.name}
            </Box>
          </Box>
        </Box>   
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Tags
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {jtags.map(t => <Chip size="small" label={t} key={t} className={classes.chip} />)}
            </Box>
          </Box>
        </Box>     
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Application Email
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {applicationemail}
            </Box>
          </Box>
        </Box>        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Application Website
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {applicationwebsite}
            </Box>
          </Box>
        </Box>        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Salary
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {currency?.left_symbol} {salary} {currency?.right_symbol} {max_salary && " - "} {max_salary && currency?.left_symbol} {max_salary && max_salary} {max_salary && currency?.right_symbol}
            </Box>
          </Box>
        </Box>        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Application Validity
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {startApp} {end_date && (- {closeApp} )}
            </Box>
          </Box>
        </Box>        
        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Created on
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {createdOn}
            </Box>
          </Box>
        </Box>    
      </Box>
    </Dialog>
  );
};

export default DetailView;

DetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
