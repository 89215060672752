import { errorMonitor } from 'events';
import axios from '../../../../../../services/auth/jwt/config';   // 'axios'
import {
  SET_DATUM_DETAILS,
  GET_DATA_FETCHING,
  GET_ALL_DATA_SUCCESS,
  GET_PAGED_DATA_SUCCESS,
  GET_DATA_FAILED,
  GET_SELECT_LIST_DATA_FETCHING,
  GET_SELECT_LIST_DATA_SUCCESS,
  GET_SELECT_LIST_DATA_FAILED,
  GET_DATUM_FETCHING,
  GET_DATUM_SUCCESS,
  GET_DATUM_FAILED,
  ADD_DATUM_FETCHING,
  ADD_DATUM_SUCCESS,
  ADD_DATUM_FAILED,
  UPDATE_DATUM_FETCHING,
  UPDATE_DATUM_SUCCESS,
  UPDATE_DATUM_FAILED,
  DELETE_DATUM_FETCHING,
  DELETE_DATUM_SUCCESS,
  DELETE_DATUM_FAILED,
  DELETE_DATA_FETCHING,
  DELETE_DATA_SUCCESS,
  DELETE_DATA_FAILED
} from '../type'


export const setCurrentItem = item => {
  return dispatch => {
    dispatch({ type: SET_DATUM_DETAILS, payload: item });
  };
};

// ** Get all Data
export const getAllData = (callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_DATA_FETCHING
    })

    try {
      const response = await axios.get('/v1/jobCompanies')    //.then(response => {
      
      dispatch({
        type: GET_ALL_DATA_SUCCESS,
        payload: { data: response.data }
       })
       if (callbackFunc) 
        callbackFunc();
    } catch (err) {
      dispatch({
        type: GET_DATA_FAILED,
        payload: { data: null, error: err }
       })
      if (failedFunc)
        failedFunc(err);
    }
  }
}

// ** Get data on page or row change
export const getPagedData = (params, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_DATA_FETCHING
    })

    try {
      const response = await axios.post('/v1/jobCompanies/search', params)    //.then(response => {
      dispatch({
        type: GET_PAGED_DATA_SUCCESS,
        payload: {
          data: response.data.items,
          totalPages: response.data.total,
          params
        }
      })
      if (callbackFunc) 
        callbackFunc();
    } catch (err) {
      dispatch({
        type: GET_DATA_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);
    }
  }
}

// ** Get data on page or row change
export const getSelectData = (callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_SELECT_LIST_DATA_FETCHING
    })

    try {
      const response = await axios.get('/v1/jobCompanies/select')    //.then(response => {
      dispatch({
        type: GET_SELECT_LIST_DATA_SUCCESS,
        payload: {
          data: response.data
        }
      })
      if (callbackFunc) 
        callbackFunc();

      return Promise.resolve(response.data)
    } catch (err) {
      dispatch({
        type: GET_SELECT_LIST_DATA_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

// ** Get data on page or row change
export const getSearchableSelectData = (search, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_SELECT_LIST_DATA_FETCHING
    })

    try {
      const response = await axios.post('/v1/jobCompanies/searchSelect', { search })    //.then(response => {
      dispatch({
        type: GET_SELECT_LIST_DATA_SUCCESS,
        payload: {
          data: response.data
        }
      })
      if (callbackFunc) 
        callbackFunc();

      return Promise.resolve(response.data)
    } catch (err) {
      dispatch({
        type: GET_SELECT_LIST_DATA_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

// ** Get Data
export const getData = (id, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_DATUM_FETCHING
    })

    try {
      const response = await axios.get(`/v1/jobCompanies/${id}`)      //.then(response => {
      dispatch({
        type: GET_DATUM_SUCCESS,
        payload: { selectedItem: response.data }
      })
      if (callbackFunc) 
        callbackFunc();

    } catch (err) {
      dispatch({
        type: GET_DATUM_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);
    }
  }
}

// ** Add new Data
export const addData = (data, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: ADD_DATUM_FETCHING
    })

    try {
      const response = await axios.post('/v1/jobCompanies/add', data)       //.then(response => {             
      dispatch({
        type: ADD_DATUM_SUCCESS,
        payload: { data: response.data }
      })
      if (callbackFunc) 
        callbackFunc(data);

      return Promise.resolve(response.data)
    } catch (err) {
      dispatch({
        type: ADD_DATUM_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(errorMonitor);

      return Promise.reject(err)
    }
  }
}


// ** Add new State
export const updateData = (data, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: UPDATE_DATUM_FETCHING
    })
    
    try {
      const response = await axios.put(`/v1/jobCompanies/${data.hid}`, {...data})       //.then(response => {
      dispatch({
        type: UPDATE_DATUM_SUCCESS,
        payload: { data }
      })   
      if (callbackFunc) 
        callbackFunc(data);

      return Promise.resolve(data)
    } catch (err) {
      dispatch({
        type: UPDATE_DATUM_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

// ** Delete user
export const deleteData = (id, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: DELETE_DATUM_FETCHING
    })
    
    try {
      const response = await axios.delete(`/v1/jobCompanies/${id}`)     //.then(response => {
      dispatch({
        type: DELETE_DATUM_SUCCESS
      })
      if (callbackFunc)
        callbackFunc();

      return Promise.resolve(id)
    } catch (err) {
      dispatch({
        type: DELETE_DATUM_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}


export const deleteBulkData = (ids, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: DELETE_DATA_FETCHING
    })
    
    try {
      const response = await axios.post(`/v1/jobCompanies/bulkDelete`, { ids })     //.then(response => {
      dispatch({
        type: DELETE_DATA_SUCCESS
      })
      if (callbackFunc)
        callbackFunc();

      return Promise.resolve(ids)
    } catch (err) {
      dispatch({
        type: DELETE_DATA_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}
