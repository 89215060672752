import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import ListRow from './ListRow';
import TableHead from './TableHead';
import TableToolbar from './TableToolbar';
import { getComparator, stableSort } from '../../../../@jumbo/utils/tableHelper';
import { useDispatch, useSelector } from 'react-redux';
import { deleteData, getPagedData, removeTeamLead, removeMember, setCurrentItem } from '../ftmgmtgroup/store/action';    // '../../../redux/actions/Users';
import AddEditTeamLeadDialog from './AddEditTeamLeadDialog';
import AddEditMemberDialog from './AddEditMemberDialog';
import ConfirmDialog from '../../../../@jumbo/components/Common/ConfirmDialog';
import { useDebounce, usePreviousValue } from '../../../../@jumbo/utils/commonHelper';
import useStyles from './index.style';
import DetailView from './DetailView';
import NoRecordFound from './NoRecordFound';
import ItemCell from '../../../Dashboards/ECommerce/SimpleNotifications/ItemCell';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


const FtManagementGroupModule = () => {
  const classes = useStyles();
  const store = useSelector(state => state.ftmgmtgroups);
  const [orderBy, setOrderBy] = React.useState('groupnumber');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);  
  const [openTeamLeadDialog, setOpenTeamLeadDialog] = useState(false);
  const [openMembersDialog, setOpenMembersDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openRemoveTeamLeadConfirmDialog, setOpenRemoveTeamLeadConfirmDialog] = useState(false);
  const [openRemoveMemberConfirmDialog, setOpenRemoveMemberConfirmDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ name: '' });
  const [groupsFetched, setGroupsFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 100);

  const dispatch = useDispatch();
  const previousSearchTerm = usePreviousValue(debouncedSearchTerm);

  useEffect(() => {
    if (previousSearchTerm !== debouncedSearchTerm) 
      setPage(p => 0);
    
    dispatch(
      getPagedData({ filterOptions, page, perPage: rowsPerPage,  q: debouncedSearchTerm }, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
        setGroupsFetched(true);
      }),
    );
  }, [dispatch, filterOptions, page, rowsPerPage, debouncedSearchTerm]);

  // const handleAddSuccess = () => {
  //   dispatch(
  //     getPagedData({ filterOptions, page, perPage: rowsPerPage,  q: debouncedSearchTerm }, () => {
  //       setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
  //       setGroupsFetched(true);
  //     }),
  //   );
    
  //   NotificationManager.success("Added Successfully!", "First timer management group added successfully", 3000);
  // }

  // const handleAddFailed = (err) => {
  //   NotificationManager.error("Add failed!", `Could not add!,\n${err}`, 3000);
  // }

  // const handleUpdateSuccess = () => {
  //   dispatch(
  //     getPagedData({ filterOptions, page, perPage: rowsPerPage,  q: debouncedSearchTerm }, () => {
  //       setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
  //       setGroupsFetched(true);
  //     }),
  //   );
    
  //   NotificationManager.success("Updated Successfully!", "First timer management group updated successfully", 3000);
  // }

  // const handleUpdateFailed = (err) => {
  //   NotificationManager.error("Update failed!", `Could not update!,\n${err}`, 3000);
  // }
  
  const handleBulkDeleteSuccess = () => {
    dispatch(
      getPagedData({ filterOptions, page, perPage: rowsPerPage,  q: debouncedSearchTerm }, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
        setGroupsFetched(true);
      }),
    );
    
    NotificationManager.success("Deleted Successfully!", "First timer management group deleted successfully", 3000);
  }

  const handleBulkDeleteFailed = (err) => {
    NotificationManager.error("Delete failed!", `Could not delete!,\n${err}`, 3000);
  }

  //

  const handleAddTeamLeadSuccess = () => {
    dispatch(
      getPagedData({ filterOptions, page, perPage: rowsPerPage,  q: debouncedSearchTerm }, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
        setGroupsFetched(true);
      }),
    );
    
    NotificationManager.success("Added Team Lead Successfully!", "First timer management group added successfully", 3000);
  }

  const handleAddTeamLeadFailed = (err) => {
    NotificationManager.error("Add failed!", `Could not add!,\n${err}`, 3000);
  }

  const handleUpdateTeamLeadSuccess = () => {
    dispatch(
      getPagedData({ filterOptions, page, perPage: rowsPerPage,  q: debouncedSearchTerm }, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
        setGroupsFetched(true);
      }),
    );
    
    NotificationManager.success("Updated Team Lead Successfully!", "First timer management group updated successfully", 3000);
  }

  const handleUpdateTeamLeadFailed = (err) => {
    NotificationManager.error("Update Team Lead failed!", `Could not update!,\n${err}`, 3000);
  }

  const handleCloseTeamLeadDialog = () => {
    setOpenTeamLeadDialog(false);
    dispatch(setCurrentItem(null));
  };

  //

  
  const handleAddMemberSuccess = () => {
    dispatch(
      getPagedData({ filterOptions, page, perPage: rowsPerPage,  q: debouncedSearchTerm }, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
        setGroupsFetched(true);
      }),
    );
    
    NotificationManager.success("Added Senior Covenant Partner Successfully!", "Senior covenant partner added successfully", 3000);
  }

  const handleAddMemberFailed = (err) => {
    NotificationManager.error("Add failed!", `Could not add!,\n${err}`, 3000);
  }

  const handleUpdateMemberSuccess = () => {
    dispatch(
      getPagedData({ filterOptions, page, perPage: rowsPerPage,  q: debouncedSearchTerm }, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
        setGroupsFetched(true);
      }),
    );
    
    NotificationManager.success("Updated Senior Covenant Partners Successfully!", "Senior covenant partner updated successfully", 3000);
  }

  const handleUpdateMemberFailed = (err) => {
    NotificationManager.error("Update Senior Covenant Partners failed!", `Could not update!,\n${err}`, 3000);
  }

  const handleCloseMemberDialog = () => {
    setOpenMembersDialog(false);
    dispatch(setCurrentItem(null));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    dispatch(setCurrentItem(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = store.data.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleView = item => {
    dispatch(setCurrentItem(item));
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentItem(null));
  };

  const handleEdit = item => {
    dispatch(setCurrentItem(item));
    setOpenDialog(true);
  };

  //

  const handleTeamLeadEdit = item => {
    dispatch(setCurrentItem(item));
    setOpenTeamLeadDialog(true);
  };

  //

  const handleMemberAdd = item => {
    dispatch(setCurrentItem(item));
    setOpenMembersDialog(true);
  };

  const handleRemoveTeamLead = item => {
    //console.log(item)
    setSelectedItem(item);
    setOpenRemoveTeamLeadConfirmDialog(true);
  }

  const handleRemoveMember = item => {
    console.log(item)
    setSelectedItem(item);
    setOpenRemoveMemberConfirmDialog(true);
  }

  const handleTransferFirstTimers = item => {
    console.log(item)
    setSelectedItem(item);
  }

  const handleCancelRemoveTeamLead = () => {
    setSelectedItem(null);
    setOpenRemoveTeamLeadConfirmDialog(false);
  }

  const handleConfirmRemoveTeamLead = () => {
    dispatch(removeTeamLead(selectedItem.id, () => {
      NotificationManager.success("Removed Successfully!", "Team lead removed successfully", 3000);     
      setSelected([]);
      dispatch(
        getPagedData({ filterOptions, page, perPage: rowsPerPage,  q: debouncedSearchTerm }, () => {
          setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
          setGroupsFetched(true);
        }),
      );
    }, (err) => {    
      NotificationManager.error("Remove failed!", `Could not delete!,\n${err}`, 3000);
    }));

    setOpenRemoveTeamLeadConfirmDialog(false);
  }
  
  const handleCancelRemoveMember = () => {
    setSelectedItem(null);
    setOpenRemoveMemberConfirmDialog(false);
  }
  
  const handleConfirmRemoveMember = () => {
    dispatch(removeMember(selectedItem.id, () => {
      NotificationManager.success("Removed Successfully!", "Member removed successfully", 3000);     
      setSelected([]);
      dispatch(
        getPagedData({ filterOptions, page, perPage: rowsPerPage,  q: debouncedSearchTerm }, () => {
          setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
          setGroupsFetched(true);
        }),
      );
    }, (err) => {    
      NotificationManager.error("Remove failed!", `Could not delete!,\n${err}`, 3000);
    }));

    setOpenRemoveMemberConfirmDialog(false);
  }

  // const handleDelete = item => {
  //   setSelectedItem(item);
  //   setOpenConfirmDialog(true);
  // };

  // const handleConfirmDelete = () => {

  //   dispatch(deleteData(selectedItem.guid, () => {
  //     NotificationManager.success("Deleted Successfully!", "First timer management group deleted successfully", 3000);     
  //     setSelected([]);
  //     dispatch(
  //       getPagedData({ filterOptions, page, perPage: rowsPerPage,  q: debouncedSearchTerm }, () => {
  //         setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
  //         setGroupsFetched(true);
  //       }),
  //     );
  //   }, (err) => {    
  //     NotificationManager.error("Delete failed!", `Could not delete!,\n${err}`, 3000);
  //   }));

  //   setOpenConfirmDialog(false);
  // };

  // const handleCancelDelete = () => {
  //   setOpenConfirmDialog(false);
  // };


  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          selected={selected}
          setSelected={setSelected}
          onAdd={setOpenDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onBulkDeleteSuccess={handleBulkDeleteSuccess}
          onBulkDeleteFailed={handleBulkDeleteFailed}
          loading={!store.finish}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
            <TableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={store.data.length}
            />
            <TableBody>
              {!!store.data.length ? (
                stableSort(store.data, getComparator(order, orderBy))
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <ListRow
                      key={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onUpdateTeamLead={handleTeamLeadEdit}
                      onAddMember={handleMemberAdd}
                      onView={handleView}
                      onRemoveTeamLead={handleRemoveTeamLead}
                      onRemoveMember={handleRemoveMember}
                      onTransferFirstTimers={handleTransferFirstTimers}
                      isSelected={isSelected}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {isFilterApplied ? (
                      <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                    ) : (
                      <NoRecordFound>{groupsFetched ? 'There are no records found.' : 'Loading first timer management groups...'}</NoRecordFound>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={store.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openTeamLeadDialog && <AddEditTeamLeadDialog 
                              open={openTeamLeadDialog} 
                              onCloseDialog={handleCloseTeamLeadDialog} 
                              onAddSuccess={handleAddTeamLeadSuccess}
                              onAddFailed={handleAddTeamLeadFailed}
                              onUpdateSuccess={handleUpdateTeamLeadSuccess}
                              onUpdateFailed={handleUpdateTeamLeadFailed} />}
      {openMembersDialog && <AddEditMemberDialog 
                              open={openMembersDialog} 
                              onCloseDialog={handleCloseMemberDialog} 
                              onAddSuccess={handleAddMemberSuccess}
                              onAddFailed={handleAddMemberFailed}
                              onUpdateSuccess={handleUpdateMemberSuccess}
                              onUpdateFailed={handleUpdateMemberFailed} />}                              
      {openViewDialog && <DetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}

      <ConfirmDialog
        open={openRemoveTeamLeadConfirmDialog}
        title={`Confirm delete ${selectedItem?.name}`}
        content={'Are you sure, you want to remove team leader from Group?'}
        onClose={handleCancelRemoveTeamLead}
        onConfirm={handleConfirmRemoveTeamLead}
        loading={!store.finish} />

      <NotificationContainer />
    </div>
  );
};

export default FtManagementGroupModule;
