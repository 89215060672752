import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../@coremat/CmtList';   // '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../@jumbo/components/Common/formElements/AppSelectBox';
import AppDatePicker from '../../../../@jumbo/components/Common/formElements/AppDatePicker';
import { emailNotValid, requiredMessage } from '../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { 
  getData, 
  addData, 
  updateData, 
} from '../store/action';


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));


function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} format="#### ### ####" />;
}

const AddEditDialog = ({ open, onCloseDialog, onAddSuccess, onAddFailed, onUpdateSuccess, onUpdateFailed }) => {
  const classes = useStyles();

  const { selectedItem, titles, genders, maritalStatuses, statuses, countryCodes, eduLevels, finish } = useSelector(({ accounts }) => accounts);
  //const countryStore = useSelector(state => state.countries);

  const [educationLevel, setEducationLevel] = useState(selectedItem?.levelofeducation ?? '');
  const [educationLevelError, setEducationLevelError] = useState('');

  const [currentAdmin, setCurrentAdmin] = useState(selectedItem);

  const dispatch = useDispatch();

  useEffect(() => {

    setCurrentAdmin(selectedItem);

  }, []);

  const onSubmitClick = () => {

    //email && isValidEmail(email) &&
    if (educationLevel) {
      onAdminSave();
    }
  };

  const onAdminSave = () => {
    const newAdmin = {
      levelOfEducation: educationLevel
    };

    if (currentAdmin) {
      dispatch(
        updateData({ ...currentAdmin, ...newAdmin }, () => {
          if (onUpdateSuccess)
            onUpdateSuccess();
          onCloseDialog();
        }, (err) => {
          if (onUpdateFailed)
            onUpdateFailed(err);
        }),
      );
    } else {
      dispatch(
        addData(newAdmin, () => {
          if (onAddSuccess)
            onAddSuccess();
          onCloseDialog();
        }, (err) => {
          if (onAddFailed)
            onAddFailed(err);
        }),
      );
    }
  };

  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{currentAdmin ? 'Edit Admin Details' : 'Create New Admin'}</DialogTitle>
        <DialogContent dividers>
        {selectedItem?.names && (
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={4}>
                <Box component="span" fontSize={12} color="text.secondary">
                  Names
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box component="span" fontSize={16} ml={3}>
                  {selectedItem?.names}
                </Box>
              </Grid>
            </GridContainer>
          </Box>
        )}
        {selectedItem?.email && (
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={4}>
                <Box component="span" fontSize={12} color="text.secondary">
                  Email
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box component="span" fontSize={16} ml={3}>
                  {selectedItem?.email}
                </Box>
              </Grid>
            </GridContainer>
          </Box>
        )}

          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Education Level"
                  value={educationLevel}
                  data={eduLevels}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setEducationLevel(e.target.value);
                    setEducationLevelError('');
                  }}
                  helperText={educationLevelError}
                />
              </Grid>
            </GridContainer>
          </Box>

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button disabled={!finish} variant="contained" color="primary" onClick={onSubmitClick}>
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default AddEditDialog;

AddEditDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
