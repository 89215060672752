import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../../@coremat/CmtList';   // '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
//import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { getData, addData, updateData } from '../store/action';    // '../../../../redux/actions/Users';
import { getSelectData as countrySelectData } from '../../country/store/action';
import { getSelectDataById as stateSelectDataById } from '../../state/store/action';
import { getSelectDataById as lgaSelectDataById } from '../../lga/store/action';


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));



const AddEditDialog = ({ open, onCloseDialog, onAddSuccess, onAddFailed, onUpdateSuccess, onUpdateFailed }) => {
  const classes = useStyles();

  const { selectedItem, finish } = useSelector(({ locations }) => locations);
  const countryStore = useSelector(state => state.countries);
  const stateStore = useSelector(state => state.states);
  const lgaStore = useSelector(state => state.lgas);

  const [town, setTown] = useState(selectedItem?.town);
  const [townError, setTownError] = useState('');
  
  const [lgaId, setLgaId] = useState(selectedItem?.lgaid);
  const [lgaError, setLgaError] = useState('');
  
  const [stateId, setStateId] = useState(selectedItem?.lga?.stateid);
  const [stateError, setStateError] = useState('');

  const [countryId, setCountryId] = useState(selectedItem?.lga?.state?.countryid);
  const [countryError, setCountryError] = useState('');

  const [currentLocation, setCurrentLocation] = useState(selectedItem);

  const dispatch = useDispatch();

  useEffect(() => {

    setCurrentLocation(selectedItem);

    dispatch(countrySelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))

    dispatch(stateSelectDataById(countryId, () => {
      // do nothing
    }, () => {
      // do nothing
    }))

    dispatch(lgaSelectDataById(stateId, () => {
      // do nothing
    }, () => {
      // do nothing
    }))
  }, [countryId, stateId]);


  const onSubmitClick = () => {
    if (!town) {
      setTownError(requiredMessage);
    }
    if (!lgaId) {
      setLgaError(requiredMessage);
    }
    if (!stateId) {
      setStateError(requiredMessage);
    }
    if (!countryId) {
      setCountryError(requiredMessage);
    }
    if (town && lgaId && stateId && countryId) {
      onLocationSave();
    }
  };

  const onLocationSave = () => {
    const newLocation = {
      town: town,
      lgaId: lgaId
    };

    if (currentLocation) {
      dispatch(
        updateData({ ...currentLocation, ...newLocation }, () => {
          if (onUpdateSuccess)
            onUpdateSuccess();
          onCloseDialog();
        }, (err) => {
          if (onUpdateFailed)
            onUpdateFailed(err);
        }),
      );
    } else {
      dispatch(
        addData(newLocation, () => {
          if (onAddSuccess)
            onAddSuccess();
          onCloseDialog();
        }, (err) => {
          if (onAddFailed)
            onAddFailed(err);
        }),
      );
    }
  };

  //console.log(selectedItem)

  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{currentLocation ? 'Edit Location Details' : 'Create New Location'}</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Town"
                  value={town}
                  onChange={e => {
                    setTown(e.target.value);
                    setTownError('');
                  }}
                  helperText={townError}
                />
              </Grid>          
            </GridContainer>
          </Box>

          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Country"
                  value={countryId}
                  data={countryStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setCountryId(e.target.value);
                  }}
                  helperText={countryError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="State"
                  value={stateId}
                  data={stateStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setStateId(e.target.value);
                  }}
                  helperText={stateError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Lga"
                  value={lgaId}
                  data={lgaStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setLgaId(e.target.value);
                  }}
                  helperText={lgaError}
                />
              </Grid>
            </GridContainer>
          </Box>          
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button disabled={!finish} variant="contained" color="primary" onClick={onSubmitClick}>
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default AddEditDialog;

AddEditDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
