
export const SET_DATUM_DETAILS = 'SET_DISTRICT_DETAILS'

export const GET_COUNT_FETCHING = 'GET_DISTRICT_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_DISTRICT_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_DISTRICT_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_DISTRICTS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_DISTRICTS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_DISTRICTS_SUCCESS'
export const GET_DATA_FAILED = 'GET_DISTRICTS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_DISTRICTS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_DISTRICTS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_DISTRICTS_FAILED'

export const GET_SELECT_MEMBERS_DATA_FETCHING = 'GET_SELECT_DISTRICT_MEMBERS_FETCHING'
export const GET_SELECT_MEMBERS_DATA_SUCCESS = 'GET_SELECT_DISTRICT_MEMBERS_SUCCESS'
export const GET_SELECT_MEMBERS_DATA_FAILED = 'GET_SELECT_DISTRICT_MEMBERS_FAILED'

export const GET_DATUM_FETCHING = 'GET_DISTRICT_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_DISTRICT_SUCCESS'
export const GET_DATUM_FAILED = 'GET_DISTRICT_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_DISTRICT_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_DISTRICT_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_DISTRICT_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_DISTRICT_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_DISTRICT_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_DISTRICT_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_DISTRICT_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_DISTRICT_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_DISTRICT_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_DISTRICTS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_DISTRICTS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_DISTRICTS_FAILED'
