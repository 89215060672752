
export const SET_DATUM_DETAILS = 'SET_CELL_DETAILS'

export const GET_COUNT_FETCHING = 'GET_CELL_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_CELL_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_CELL_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_CELLS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_CELLS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_CELLS_SUCCESS'
export const GET_DATA_FAILED = 'GET_CELLS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_CELLS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_CELLS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_CELLS_FAILED'

export const GET_SELECT_MEMBERS_DATA_FETCHING = 'GET_SELECT_CELL_MEMBERS_FETCHING'
export const GET_SELECT_MEMBERS_DATA_SUCCESS = 'GET_SELECT_CELL_MEMBERS_SUCCESS'
export const GET_SELECT_MEMBERS_DATA_FAILED = 'GET_SELECT_CELL_MEMBERS_FAILED'

export const GET_DATUM_FETCHING = 'GET_CELL_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_CELL_SUCCESS'
export const GET_DATUM_FAILED = 'GET_CELL_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_CELL_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_CELL_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_CELL_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_CELL_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_CELL_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_CELL_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_CELL_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_CELL_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_CELL_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_CELLS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_CELLS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_CELLS_FAILED'
