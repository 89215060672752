
export const SET_DATUM_DETAILS = 'SET_LOCATION_DETAILS'

export const GET_DATA_FETCHING = 'GET_LOCATIONS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_LOCATIONS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_LOCATIONS_SUCCESS'
export const GET_DATA_FAILED = 'GET_LOCATIONS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_LOCATIONS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_LOCATIONS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_LOCATIONS_FAILED'

export const GET_DATUM_FETCHING = 'GET_LOCATION_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_LOCATION_SUCCESS'
export const GET_DATUM_FAILED = 'GET_LOCATION_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_LOCATION_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_LOCATION_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_LOCATION_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_LOCATION_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_LOCATION_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_LOCATION_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_LOCATION_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_LOCATION_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_LOCATION_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_LOCATIONS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_LOCATIONS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_LOCATIONS_FAILED'
