
export const SET_DATUM_DETAILS = 'SET_LOVE_CENTER_DETAILS'

export const GET_COUNT_FETCHING = 'GET_LOVE_CENTER_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_LOVE_CENTER_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_LOVE_CENTER_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_LOVE_CENTERS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_LOVE_CENTERS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_LOVE_CENTERS_SUCCESS'
export const GET_DATA_FAILED = 'GET_LOVE_CENTERS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_LOVE_CENTERS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_LOVE_CENTERS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_LOVE_CENTERS_FAILED'

export const GET_SELECT_MEMBERS_DATA_FETCHING = 'GET_SELECT_L_MEMBERS_FETCHING'
export const GET_SELECT_MEMBERS_DATA_SUCCESS = 'GET_SELECT_L_MEMBERS_SUCCESS'
export const GET_SELECT_MEMBERS_DATA_FAILED = 'GET_SELECT_L_MEMBERS_FAILED'

export const GET_DATUM_FETCHING = 'GET_LOVE_CENTER_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_LOVE_CENTER_SUCCESS'
export const GET_DATUM_FAILED = 'GET_LOVE_CENTER_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_LOVE_CENTER_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_LOVE_CENTER_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_LOVE_CENTER_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_LOVE_CENTER_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_LOVE_CENTER_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_LOVE_CENTER_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_LOVE_CENTER_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_LOVE_CENTER_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_LOVE_CENTER_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_LOVE_CENTERS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_LOVE_CENTERS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_LOVE_CENTERS_FAILED'
